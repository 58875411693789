import { useCallback } from "react";
import { justPayPageTypesEnum } from "../../../enums/justPayPageTypesEnum";
import CheckLabel from "./CheckLabel";
import { CollapsibleBox } from "../CollapsibleBox";

const TaxesSection = ({ entity }) => {
  const { entityType, selections, checks } = entity;

  const groupTaxesByName = useCallback(() => {
    const processTaxes = (taxes) => {
      return taxes.reduce((acc, { name, taxAmount }) => {
        acc[name] = (acc[name] || 0) + taxAmount;
        return acc;
      }, {});
    };

    if (entityType === justPayPageTypesEnum.check) {
      return processTaxes(selections.flatMap((selection) => selection.appliedTaxes));
    }

    if (entityType === justPayPageTypesEnum.order) {
      const allTaxes = checks.flatMap((check) => check.selections.flatMap((selection) => selection.appliedTaxes));
      return processTaxes(allTaxes);
    }

    return {};
  }, [checks, entityType, selections]);

  return (
    <CollapsibleBox
      children={Object.entries(groupTaxesByName(entity)).map(([key, amount]) => (
        <CheckLabel key={key} label={`- ${key}`} amount={amount} divClassName="check-label-secondary padding-zero" />
      ))}
      heading="Taxes:"
      headingAmount={entity?.checkout?.taxAmount}
    />
  );
};

export default TaxesSection;
