import { round } from "lodash";
import { justPayPageTypesEnum } from "../../../enums/justPayPageTypesEnum";
import CheckLabel from "./CheckLabel";
import { CollapsibleBox } from "../CollapsibleBox";

const ItemsSection = ({ entity }) => {
  const { entityType, selections, checks } = entity;

  const renderModifiers = (modifiers) =>
    modifiers
      ?.filter((modifier) => modifier.price > 0)
      .map((modifier) => (
        <CheckLabel
          key={modifier.guid}
          label={`${modifier.displayName} x ${modifier.quantity}`}
          divClassName="check-label-secondary padding-zero"
          labelClassName="check-label-modifiers"
        />
      ));

  const renderSelectionItem = (selection) => (
    <div className="render-selection-item-container" key={selection.guid}>
      <div className="d-flex justify-content-between">
        <div className="render-selection-item-info-container">
          <p>
            {selection?.displayName} x {selection?.quantity}:
          </p>
          {renderModifiers(selection.modifiers)}
        </div>

        {selection?.preDiscountPrice !== selection?.price ? (
          <div className="render-selection-item-discount-container">
            <h6 className="item-discounted-price">${round(selection?.preDiscountPrice, 2)}</h6>
            <h6>${round(selection?.price, 2)}</h6>
          </div>
        ) : (
          <h6>${round(selection?.price, 2)}</h6>
        )}
      </div>
    </div>
  );

  if (entityType === justPayPageTypesEnum.check) {
    return (
      <CollapsibleBox
        children={selections?.map(renderSelectionItem)}
        heading="Subtotal: "
        headingAmount={entity?.checkout?.voilaSubTotalAmount}
      />
    );
  } else if (entityType === justPayPageTypesEnum.order) {
    return (
      <CollapsibleBox
        children={checks?.map((check) => check?.selections?.map(renderSelectionItem))}
        heading="Subtotal: "
        headingAmount={entity?.checkout?.voilaSubTotalAmount}
      />
    );
  }
};

export default ItemsSection;
