import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/modal/Modal";
import ModalHeading from "../../components/common/ModalHeading";
import InputField from "../../components/common/InputField";
import CustomButton from "../../components/common/CustomButton";
import { setCheck } from "../../store/actions/justPayActions";

function CheckSplitModal({ onCloseModal, showModal, onOpenPaymentModalHandler }) {
  const dispatch = useDispatch();

  const {
    justPay: {
      check: { split: oldValue },
    },
  } = useSelector((state) => state);

  const [newValue, setNewValue] = useState(oldValue ?? 2);

  const onConfirm = () => {
    dispatch(setCheck({ split: newValue, paymentPercentage: null, paymentAmount: null }));
    onCloseModal();
    onOpenPaymentModalHandler();
  };

  const onChangeHandler = (e) => {
    if (
      typeof +e.target.value === "number" &&
      +e.target.value >= 2 &&
      +e.target.value <= 10 &&
      !e.target.value.includes(".")
    ) {
      setNewValue(parseInt(e.target.value));
    }
  };

  return (
    <>
      <Modal isPopUp className="pop-up-modal" show={showModal} close={onCloseModal}>
        <ModalHeading title="Split Check" onClick={onCloseModal} />

        <InputField type="number" containerClassName="mt-4" value={newValue} onChange={onChangeHandler} />

        <CustomButton
          wrapperClassName="booking-submit-btn active mx-auto mt-3"
          textClassName="booking-submit-btn-text active"
          text="Confirm"
          onClick={onConfirm}
        />
      </Modal>
    </>
  );
}

export default CheckSplitModal;
