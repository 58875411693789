import classNames from "classnames";
import { round } from "lodash";
import { useSelector } from "react-redux";

const CheckoutPaymentPreview = ({
  checkout,
  onOpenCheckSplitModalHandler,
  onOpenPaymentModalHandler,
  onOpenPaymentTipModalHandler,
  alreadySplittedCheck,
  actionButtons,
}) => {
  const {
    justPay: {
      check: { split, paymentPercentage, paymentAmount, paymentTipPercentage, paymentTipAmount },
    },
  } = useSelector((state) => state);

  const balanceDuePtc = round((checkout?.balanceDue / checkout?.totalAmount) * 100, 2);
  const settledPtc = round(100 - balanceDuePtc, 2);

  return (
    <div className="checkout-payment-preview-container">
      <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
        <span>
          Check Split:
          <button
            className={classNames("editTip-button", alreadySplittedCheck && "disabled-change-button")}
            onClick={onOpenCheckSplitModalHandler}
            disabled={alreadySplittedCheck}
          >
            Change
          </button>
        </span>
        <span className={"totalTip"}>{split}</span>
      </div>

      <div style={{ marginBottom: "0.5rem" }}>Currently {settledPtc}% of the check has been settled</div>

      <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
        <span>
          Payment Amount {paymentPercentage}%:{" "}
          <button
            className={classNames(
              "editTip-button",

              (!(alreadySplittedCheck || split) || actionButtons.length <= 1) && "disabled-change-button",
            )}
            onClick={onOpenPaymentModalHandler}
            disabled={!(alreadySplittedCheck || split) || actionButtons.length <= 1}
          >
            Change
          </button>
        </span>
        <span className={"totalTip"}>${paymentAmount}</span>
      </div>
      <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
        <span>
          Payment Tip {paymentTipPercentage}%:{" "}
          <button className={"editTip-button"} onClick={onOpenPaymentTipModalHandler}>
            Change
          </button>
        </span>
        <span className={"totalTip"}>${paymentTipAmount}</span>
      </div>
      <div style={{ marginBottom: "0.5rem" }} className="d-flex justify-content-between">
        <span>
          <strong>Payment Total:</strong>
        </span>
        <span className={"totalTip"}>${round(paymentAmount + paymentTipAmount, 2)}</span>
      </div>
    </div>
  );
};

export default CheckoutPaymentPreview;
