import axios from "axios";
import {
  just_pay_table_order_api,
  just_pay_table_orders_api,
  just_pay_add_payment_to_check_api,
} from "../../config/api_endpoints";
import { SET_JUST_PAY_TABLE_ORDERS, SET_JUST_PAY_TABLE_ORDER, SET_JUST_PAY_CHECK } from "../../config/actionTypes";
import { setErrors } from "./errorsActions";
import { setLoader } from "./feedbackActions";

export const getOrdersByTable = (restaurantGuid, tableGuid) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      just_pay_table_orders_api.replace(":restaurantGuid", restaurantGuid).replace(":tableGuid", tableGuid),
    );
    dispatch(setOrdersByTable(data.result));
  } catch (err) {
    dispatch(setErrors(err), () => getOrdersByTable(restaurantGuid, tableGuid));
  }
};

export const setOrdersByTable = (payload) => (dispatch) => {
  dispatch({ type: SET_JUST_PAY_TABLE_ORDERS, payload });
};

export const getOrderByGuid = (restaurantGuid, tableGuid, orderGuid) => async (dispatch) => {
  try {
    const { data } = await axios.get(
      just_pay_table_order_api
        .replace(":restaurantGuid", restaurantGuid)
        .replace(":tableGuid", tableGuid)
        .replace(":orderGuid", orderGuid),
    );
    dispatch(setOrderByGuid(data.result));
  } catch (err) {
    dispatch(setErrors(err), () => getOrderByGuid(restaurantGuid, tableGuid, orderGuid));
  }
};

export const setOrderByGuid = (payload) => (dispatch) => {
  dispatch({ type: SET_JUST_PAY_TABLE_ORDER, payload });
};

export const setCheck = (payload) => (dispatch) => {
  dispatch({ type: SET_JUST_PAY_CHECK, payload });
};

export const addPaymentToCheck = (
  restaurantGuid,
  orderGuid,
  checkGuid,
  spreedlyInformation,
  checkSplit,
  paymentAmount,
  tipAmount,
) => async (dispatch) => {
  try {
    dispatch(setLoader(true));
    const result = await axios.post(
      just_pay_add_payment_to_check_api
        .replace(":restaurantGuid", restaurantGuid)
        .replace(":orderGuid", orderGuid)
        .replace(":checkGuid", checkGuid),
      { spreedlyInformation, checkSplit, paymentAmount, tipAmount },
    );

    // if (!isEmpty(data.spreedlyInformation))
    //   data.is_anonymous
    //     ? dispatch(setActiveModal(payment_done_anon_user_modal))
    //     : dispatch(setActiveModal(payment_done_modal));
  } catch (err) {
    // dispatch(
    //   setToastMessage({
    //     message: err.response.data.message || "Payment Error occured",
    //     severity: "error",
    //     timer: 10000,
    //   }),
    // );
    dispatch(setErrors(err, () => addPaymentToCheck(restaurantGuid, orderGuid, checkGuid, spreedlyInformation)));
  } finally {
    dispatch(setLoader(false));
  }
};
