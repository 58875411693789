import React, { useState } from "react";
import { round } from "lodash";
import { ExpandLessIcon200, ExpandMoreIcon200 } from "../../assets/svgs-google/GoogleIcons";

export const CollapsibleBox = ({ children, heading, headingAmount, amountInMinus }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBox = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  return (
    <div className="collapsible-box-container">
      <div onClick={toggleBox}>
        <div className="collapsible-box-container-heading">
          <p>{heading}</p>

          <div className="collapsible-box-container-heading-right">
            {isOpen ? ExpandLessIcon200(32, "#020083 ") : ExpandMoreIcon200(32, "#D5D5D5")}

            {typeof headingAmount === "number" &&
              (!amountInMinus ? <p>${round(headingAmount, 2)}</p> : <p>&#8722; ${round(headingAmount, 2)}</p>)}
          </div>
        </div>
      </div>

      {isOpen && (
        <div>
          <hr />
          {children}
        </div>
      )}
    </div>
  );
};
