import CheckSplitModal from "../../../pages/justpay/CheckSplitModal";
import PaymentModal from "../../../pages/justpay/PaymentModal";
import PaymentTipModal from "../../../pages/justpay/PaymentTipModal";

const CheckoutModals = ({
  checkout,
  showCheckSplitModal,
  showPaymentModal,
  showPaymentTipModal,
  onCloseCheckSplitModalHandler,
  onClosePaymentModalHandler,
  onClosePaymentTipModalHandler,
  actionButtons,
  settledPct,
  onOpenPaymentModalHandler,
}) => {
  return (
    <>
      {showCheckSplitModal && (
        <CheckSplitModal
          showModal={showCheckSplitModal}
          onCloseModal={onCloseCheckSplitModalHandler}
          onOpenPaymentModalHandler={onOpenPaymentModalHandler}
        />
      )}
      {showPaymentModal && (
        <PaymentModal
          showModal={showPaymentModal}
          onCloseModal={onClosePaymentModalHandler}
          actionButtons={actionButtons}
          settledPct={settledPct}
        />
      )}
      {showPaymentTipModal && (
        <PaymentTipModal
          amount={checkout?.voilaSubTotalAmount}
          showTipModal={showPaymentTipModal}
          onCloseTipModal={onClosePaymentTipModalHandler}
        />
      )}
    </>
  );
};

export default CheckoutModals;
