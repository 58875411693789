import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { round } from "lodash";
import { setCheck } from "../../store/actions/justPayActions";
import "./PaymentTipModal.css";
import Modal from "../../components/modal/Modal";
import ModalHeading from "../../components/common/ModalHeading";
import InputField from "../../components/common/InputField";
import CustomButton from "../../components/common/CustomButton";
import { defaultTipPercentage } from "../../config/keys";

// this component is a copy of components\orders\EditTipModal
function PaymentTipModal({ onCloseTipModal, showTipModal, amount }) {
  const {
    justPay: {
      check: { paymentTipPercentage: savedTip },
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();

  const defaultTipOption = defaultTipPercentage;
  const [firstOption, secondOption, thirdOption] = [10, defaultTipOption, 20];
  const isTipSaved = savedTip !== null && savedTip >= 0 && savedTip <= 100;
  const [nextTip, setNextTip] = useState(isTipSaved ? +savedTip : defaultTipOption);
  const [nextTipInput, setNextTipInput] = useState(isTipSaved ? +savedTip : defaultTipOption);
  const [showCustomTipModal, setShowCustomTipModal] = useState(false);
  const isOtherTip = ![firstOption, secondOption, thirdOption].includes(nextTip);

  const onEditTipHandler = () => setShowCustomTipModal(true);
  const onCloseOtherTipModal = () => {
    // workaround so that the input field can be empty
    setNextTip(!isNaN(nextTipInput) ? nextTipInput : 0);
    setShowCustomTipModal(false);
  };

  const onConfirmNextTip = () => {
    dispatch(setCheck({ paymentTipPercentage: nextTip, paymentTipAmount: round((amount * nextTip / 100), 2) }))
    onCloseTipModal();
  };

  const onTipChangeHandler = (e) => {
    if (
      typeof +e.target.value === "number" &&
      +e.target.value >= 0 &&
      +e.target.value <= 100 &&
      !e.target.value.includes(".")
    ) {
      setNextTipInput(parseInt(e.target.value));
    }
  };

  return (
    <>
      {!showCustomTipModal && (
        <Modal
          isPopUp
          className={`cancel-reservation-modal ${showCustomTipModal ? "edit-tip-modal" : "edit-tip-modal-no-tip"}`}
          show={showTipModal}
          close={onCloseTipModal}
        >
          <ModalHeading
            className={"push-heading"}
            title="Custom tip"
            onClick={onCloseTipModal}
          />
          <div className="edit-tip-container">
            <CustomButton
              onClick={() => setNextTip(firstOption)}
              wrapperClassName={classnames("booking-btn", { active: nextTip === firstOption })}
              textClassName={classnames("booking-btn-text", { active: nextTip === firstOption })}
              text={`${firstOption}%`}
            />
            <CustomButton
              onClick={() => setNextTip(secondOption)}
              wrapperClassName={classnames("booking-btn", { active: nextTip === secondOption })}
              textClassName={classnames("booking-btn-text", { active: nextTip === secondOption })}
              text={`${secondOption}%`}
            />
            <CustomButton
              onClick={() => setNextTip(thirdOption)}
              wrapperClassName={classnames("booking-btn", { active: nextTip === thirdOption })}
              textClassName={classnames("booking-btn-text", { active: nextTip === thirdOption })}
              text={`${thirdOption}%`}
            />
            <CustomButton
              onClick={onEditTipHandler}
              wrapperClassName={classnames("booking-btn", { active: isOtherTip })}
              textClassName={classnames("booking-btn-text", { active: isOtherTip })}
              text={isOtherTip ? `${nextTip}%` : "Custom"}
            />
          </div>

          <div className="mt-4">
            <h3>Total amount:</h3>
            <span>${round(((amount * nextTip) / 100), 2).toFixed(2)}</span>
          </div>

          <CustomButton
            wrapperClassName="booking-submit-btn active mx-auto mt-3"
            textClassName="booking-submit-btn-text active"
            text="Confirm"
            onClick={onConfirmNextTip}
          />
        </Modal>
      )}

      {showCustomTipModal && (
        <Modal
          isPopUp
          className="cancel-reservation-modal edit-tip-modal-no-tip"
          show={showTipModal}
          close={onCloseTipModal}
        >
          <ModalHeading className="password-reset-header push-heading" title="Custom Tip" onClick={onCloseTipModal} />

          <InputField type="number" containerClassName="mt-4" value={nextTipInput} onChange={onTipChangeHandler} />

          <CustomButton
            wrapperClassName="booking-submit-btn active mx-auto mt-3"
            textClassName="booking-submit-btn-text active"
            text="Confirm"
            onClick={onCloseOtherTipModal}
          />
        </Modal>
      )}
    </>
  );
}

export default PaymentTipModal;
