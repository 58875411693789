import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
import { round } from "lodash";
import { setCheck } from "../../store/actions/justPayActions";
import "./PaymentModal.css";
import Modal from "../../components/modal/Modal";
import ModalHeading from "../../components/common/ModalHeading";
import CustomButton from "../../components/common/CustomButton";
import { AddIcon, RemoveIcon } from "../../assets/svgs-google/GoogleIcons";

function PaymentModal({ showModal, onCloseModal, actionButtons, settledPct }) {
  const dispatch = useDispatch();

  const {
    justPay: {
      check: { paymentPercentage: existingPaymentPercentage, paymentAmount: existingPaymentAmount },
    },
  } = useSelector((state) => state);

  const [newValues, setNewValues] = useState({
    pct: existingPaymentPercentage,
    amount: existingPaymentAmount,
  });

  const currentSelectedActionButtonIndex = useMemo(
    () => actionButtons.findIndex((btn) => btn.amount === newValues.amount),
    [actionButtons, newValues.amount],
  );

  useEffect(() => {
    if (newValues.pct === null && newValues.amount === null) {
      setNewValues(actionButtons[0]);
    }
  }, [actionButtons, newValues]);

  const onConfirm = () => {
    dispatch(setCheck({ paymentPercentage: newValues.pct, paymentAmount: newValues.amount }));
    onCloseModal();
  };

  const handleIncrease = () => {
    if (currentSelectedActionButtonIndex < actionButtons.length - 1) {
      setNewValues(actionButtons[currentSelectedActionButtonIndex + 1]);
    }
  };

  const handleDecrease = () => {
    if (currentSelectedActionButtonIndex > 0) {
      setNewValues(actionButtons[currentSelectedActionButtonIndex - 1]);
    }
  };

  return (
    <>
      <Modal isPopUp className={`cancel-reservation-modal edit-tip-modal`} show={showModal} close={onCloseModal}>
        <ModalHeading className={"push-heading"} title="Change Payment Amount" onClick={onCloseModal} />

        <div>
          <h5>Currently {settledPct}% of the check has been settled</h5>
        </div>

        <div className="edit-tip-container mt-2 payment-modal-btn-container">
          {actionButtons.map((btn) => (
            <CustomButton
              onClick={() => setNewValues(btn)}
              wrapperClassName={classnames("booking-btn mt-2", { active: newValues.amount === btn.amount })}
              textClassName={classnames("booking-btn-text", { active: newValues.amount === btn.amount })}
              text={btn.isLast ? "Whole" : `${btn.pct}%`}
            />
          ))}
        </div>

        <div className="mt-4">
          <h5>Total amount:</h5>

          <div className="payment-modal-total-amount-container">
            <div onClick={handleDecrease}>{RemoveIcon()}</div>

            <div>
              <span>${round(newValues.amount, 2).toFixed(2)}</span>
            </div>

            <div onClick={handleIncrease}>{AddIcon()}</div>
          </div>
        </div>

        <CustomButton
          wrapperClassName="booking-submit-btn active mx-auto mt-4"
          textClassName="booking-submit-btn-text active"
          text="Confirm"
          onClick={onConfirm}
        />
      </Modal>
    </>
  );
}

export default PaymentModal;
