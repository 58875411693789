import CheckLabel from "../CheckLabel";

const DiscountsForCheck = ({ entity }) => {
  const { appliedDiscounts } = entity;

  return (
    <>
      {appliedDiscounts?.map((discount) => (
        <CheckLabel label={discount.name} key={discount.discountType} divClassName="padding-zero" />
      ))}
    </>
  );
};

export default DiscountsForCheck;
