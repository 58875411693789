import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { timeToAMPM2 } from "../../utils/timeToAMPM";
import { just_pay_table_order_path } from "../../config/pages_paths";
import { getOrdersByTable } from "../../store/actions/justPayActions";
import "../../components/reservations/ReservationListItem.css";
import "./JustPay.css";
import ListCheck from "../../components/justpay/ListCheck";

const JustPayOrdersPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { tableGuid, restaurantGuid } = useParams();
  const {
    justPay: { orders },
    versionConfig: { close_order_if_the_first_check_on_toast_is_paid, disable_just_pay_if_more_than_one_open_order },
  } = useSelector((state) => state);
  const unclosedOrders = useMemo(() => orders?.filter((order) => !order.closedDate), [orders]);

  useEffect(() => {
    if (restaurantGuid && tableGuid) {
      dispatch(getOrdersByTable(restaurantGuid, tableGuid));
    }
  }, [dispatch, restaurantGuid, tableGuid]);

  const handleOpenTableOrder = useCallback(
    (orderGuid) => {
      history.replace(
        just_pay_table_order_path
          .replace(":tableGuid", tableGuid)
          .replace(":restaurantGuid", restaurantGuid)
          .replace(":orderGuid", orderGuid),
      );
    },
    [history, restaurantGuid, tableGuid],
  );

  useEffect(() => {
    if (close_order_if_the_first_check_on_toast_is_paid && orders?.length > 0) {
      if (unclosedOrders.length === 1) {
        handleOpenTableOrder(unclosedOrders[0].guid);
      }
    }
  }, [handleOpenTableOrder, close_order_if_the_first_check_on_toast_is_paid, orders, unclosedOrders]);

  return (
    <div className="just-pay-page-container">
      <p className="just-pay-page-title">List of orders {/* on table number */}</p>
      {disable_just_pay_if_more_than_one_open_order && unclosedOrders.length > 1 ? (
        <p
          style={{
            fontFamily: "Cormorant-Regular",
            fontSize: "22px",
          }}
        >
          Only one order can be opened on a table at a time.
        </p>
      ) : (
        <>
          {orders.length > 0 ? (
            orders?.map((check) => (
              <ListCheck
                key={check.guid}
                onClick={() => handleOpenTableOrder(check.guid)}
                guid={check.guid}
                displayNumber={check.displayNumber}
                date={timeToAMPM2(check.createdDate)}
              />
            ))
          ) : (
            <p className="not-found-text">No orders found for today.</p>
          )}
        </>
      )}
    </div>
  );
};

export default JustPayOrdersPage;
