import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getOrderByGuid, getOrdersByTable, setCheck } from "../../store/actions/justPayActions";
import { timeToAMPM2 } from "../../utils/timeToAMPM";
import ListCheck from "../../components/justpay/ListCheck";
import CheckoutInfo from "../../components/justpay/checkout/CheckoutInfo";
import CustomPieChart from "../../components/justpay/CustomPieChart";
import { justPayPageTypesEnum } from "../../enums/justPayPageTypesEnum";
import useSocket from "../../hooks/useSocket";
import { just_pay_table_order_check_path } from "../../config/pages_paths";

const JustPayOrderPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { tableGuid, restaurantGuid, orderGuid } = useParams();

  const {
    justPay: { order, orders },
    versionConfig: { close_order_if_the_first_check_on_toast_is_paid, disable_just_pay_if_more_than_one_open_order },
    feedback: { connectSocketAfterIdleTimeout },
  } = useSelector((state) => state);

  const unclosedOrders = useMemo(() => orders?.filter((order) => !order.closedDate), [orders]);

  const handleOpenCheck = useCallback(
    (checkGuid) => {
      history.replace(
        just_pay_table_order_check_path
          .replace(":tableGuid", tableGuid)
          .replace(":restaurantGuid", restaurantGuid)
          .replace(":orderGuid", orderGuid)
          .replace(":checkGuid", checkGuid),
      );

      const selectedCheck = order?.checks?.find((check) => check.guid === checkGuid);

      dispatch(setCheck({ details: selectedCheck }));
    },
    [dispatch, history, order?.checks, orderGuid, restaurantGuid, tableGuid],
  );

  useEffect(() => {
    if (restaurantGuid && tableGuid && orderGuid) {
      dispatch(getOrdersByTable(restaurantGuid, tableGuid));
      dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid));
    }
  }, [dispatch, orderGuid, restaurantGuid, tableGuid]);

  useEffect(() => {
    if (close_order_if_the_first_check_on_toast_is_paid && order?.checks?.length > 0) {
      handleOpenCheck(order.checks[0].guid);
    }
  }, [handleOpenCheck, close_order_if_the_first_check_on_toast_is_paid, order.checks]);

  const refreshJustPay = useCallback(
    ({ orderGuid }) => {
      if (restaurantGuid && tableGuid && orderGuid) {
        dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid));
      }
    },
    [dispatch, restaurantGuid, tableGuid],
  );

  useSocket({
    socketData: { orderId: orderGuid },
    shouldConnect: !connectSocketAfterIdleTimeout,
    refreshJustPay,
  });

  return (
    <div className="just-pay-page-container">
      <p className="just-pay-page-title">List of checks {/* for the selected order */}</p>
      {disable_just_pay_if_more_than_one_open_order && unclosedOrders.length > 1 ? (
        <p className="not-found-text">Only one order can be opened on a table at a time.</p>
      ) : (
        <>
          {Object.values(order).length ? (
            <>
              {order?.checks?.map((check) => (
                <ListCheck
                  key={check.guid}
                  onClick={() => handleOpenCheck(check.guid)}
                  guid={check.guid}
                  displayNumber={check.displayNumber}
                  date={timeToAMPM2(check.createdDate)}
                />
              ))}

              <CheckoutInfo entity={order} heading="Order Info" />

              <div className="pie-chart-container">
                <CustomPieChart data={order} type={justPayPageTypesEnum.order} />
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </>
      )}
    </div>
  );
};

export default JustPayOrderPage;
