import React, { useCallback, useEffect, useMemo, useState } from "react";
import CheckoutInfo from "../../components/justpay/checkout/CheckoutInfo";
import CustomPieChart from "../../components/justpay/CustomPieChart";
import SpreedlyCheckoutVault from "../../components/payment/spreedly/SpreedlyCheckoutVault";
import { addPaymentToCheck, getOrderByGuid, setCheck } from "../../store/actions/justPayActions";
import { useDispatch, useSelector } from "react-redux";
import { justPayPageTypesEnum } from "../../enums/justPayPageTypesEnum";
import useSocket from "../../hooks/useSocket";
import { useParams } from "react-router";
import { isEmpty } from "lodash";

const JustPayCheckPage = () => {
  const dispatch = useDispatch();
  const { tableGuid, restaurantGuid, orderGuid, checkGuid } = useParams();

  const {
    justPay: {
      order,
      orders,
      check: { split, paymentAmount, paymentTipAmount, details },
    },
    versionConfig: { disable_just_pay_if_more_than_one_open_order },
    feedback: { connectSocketAfterIdleTimeout },
  } = useSelector((state) => state);

  const unclosedOrders = useMemo(() => orders?.filter((order) => !order.closedDate), [orders]);

  useEffect(() => {
    if (checkGuid) {
      const selectedCheck = order?.checks?.find((check) => check.guid === checkGuid);

      if (isEmpty(details) || details?.checkout?.balanceDue !== selectedCheck?.checkout?.balanceDue) {
        dispatch(setCheck({ details: selectedCheck }));
      }
    }
  }, [checkGuid, dispatch, order?.checks, details]);

  useEffect(() => {
    if (restaurantGuid && tableGuid && orderGuid) {
      dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid));
    }
  }, [dispatch, orderGuid, restaurantGuid, tableGuid]);

  const onConfirmPaymentHandler = useCallback(
    async ({ spreedlyInformation }) => {
      checkGuid &&
        dispatch(
          addPaymentToCheck(
            restaurantGuid,
            orderGuid,
            checkGuid,
            spreedlyInformation,
            split,
            paymentAmount,
            paymentTipAmount,
          ),
        );
    },
    [checkGuid, dispatch, restaurantGuid, orderGuid, split, paymentAmount, paymentTipAmount],
  );

  const refreshJustPay = useCallback(
    ({ orderGuid }) => {
      if (restaurantGuid && tableGuid && orderGuid) {
        // WORKAROUND: TODO: FIX: PAYMENT LOOP & MORE PAYMENTS
        window.location.reload();

        // dispatch(getOrderByGuid(restaurantGuid, tableGuid, orderGuid));
      }
    },
    // [dispatch, restaurantGuid, tableGuid],
    [restaurantGuid, tableGuid],
  );

  useSocket({
    socketData: { orderId: orderGuid },
    shouldConnect: !connectSocketAfterIdleTimeout,
    refreshJustPay,
  });

  return (
    <div className="just-pay-page-container">
      {/* <p className="just-pay-page-title">Selected check</p> */}
      {disable_just_pay_if_more_than_one_open_order && unclosedOrders.length > 1 ? (
        <p className="not-found-text">Only one order can be opened on a table at a time.</p>
      ) : (
        <>
          {details && Object.values(details).length ? (
            <>
              <CheckoutInfo
                entity={details}
                existingCheckSplit={order?.checkSplit && order.checkSplit[details.guid]}
                heading="Check Info"
              />

              <div className="pie-chart-container">
                <CustomPieChart data={details} type={justPayPageTypesEnum.check} key={details.guid} />
              </div>

              {details?.checkout?.balanceDue > 0 && (
                <SpreedlyCheckoutVault onCompleteOrderClick={onConfirmPaymentHandler} isEditMode={false} />
              )}
            </>
          ) : (
            <p>Loading...</p>
          )}
        </>
      )}
    </div>
  );
};

export default JustPayCheckPage;
