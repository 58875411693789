import React, { useCallback } from "react";
import CheckLabel from "./CheckLabel";
import { groupPaymentsByCardAndCash } from "../../../utils/groupPaymentsByCardAndCash";
import { CollapsibleBox } from "../CollapsibleBox";

const PaymentsSection = ({ entity }) => {
  const { entityType, checkout } = entity;

  const paymentsData = useCallback(() => {
    return groupPaymentsByCardAndCash(entity, entityType);
  }, [entity, entityType]);

  const renderPayments = useCallback(
    (paymentsData) => (
      <>
        {Object.entries(paymentsData.credit).map(([key, amount]) => (
          <CheckLabel
            key={key}
            label={`- CREDIT, ${key}:`}
            amount={amount}
            divClassName="check-label-secondary padding-zero"
          />
        ))}
        {paymentsData.cash > 0 && (
          <CheckLabel label="- CASH:" amount={paymentsData.cash} divClassName="check-label-secondary padding-zero" />
        )}
      </>
    ),
    [],
  );

  const payments = paymentsData();

  return (
    <>
      {checkout?.totalAmount !== checkout?.paymentsAmount && (
        <CollapsibleBox
          children={
            checkout?.paymentsAmount > 0 ? (
              renderPayments(payments)
            ) : (
              <CheckLabel label="No previous payments" divClassName="check-label-no-payments padding-zero" />
            )
          }
          heading="Payments:"
          headingAmount={checkout?.paymentsAmount}
          amountInMinus
        />
      )}

      {checkout?.balanceDue > 0 && (
        <CheckLabel label={<strong>Balance due:</strong>} amount={checkout?.balanceDue} />
      )}
    </>
  );
};

export default PaymentsSection;
