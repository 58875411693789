import { useMemo } from "react";
import { justPayPageTypesEnum } from "../../../../enums/justPayPageTypesEnum";
import DiscountsForCheck from "./DiscountsForCheck";
import DiscountsForOrder from "./DiscountsForOrder";
import CheckLabel from "../CheckLabel";
import { CollapsibleBox } from "../../CollapsibleBox";

const DiscountsSection = ({ entity }) => {
  const { checkout } = entity;

  return (
    <>
      {checkout?.discountsAmount > 0 && (
        <>
          <CheckLabel label="Pre-discount subtotal:" amount={checkout?.preDiscountedSubTotalAmount} />

          <CollapsibleBox
            children={<DiscountsSectionContent entity={entity} />}
            heading="Discounts:"
            headingAmount={checkout?.discountsAmount}
            amountInMinus
          />
        </>
      )}
    </>
  );
};

const DiscountsSectionContent = ({ entity }) => {
  const { entityType, checkout } = entity;

  const hasDiscounts = useMemo(() => checkout?.preDiscountedSubTotalAmount !== checkout?.voilaSubTotalAmount, [
    checkout,
  ]);

  return (
    <>
      {entityType === justPayPageTypesEnum.check && hasDiscounts && <DiscountsForCheck entity={entity} />}

      {entityType === justPayPageTypesEnum.order && hasDiscounts && <DiscountsForOrder entity={entity} />}
    </>
  );
};

export default DiscountsSection;
