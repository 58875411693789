import React from "react";
import { groupPaymentsByCardAndCash } from "../../utils/groupPaymentsByCardAndCash";
import { pieArcLabelClasses, PieChart } from "@mui/x-charts/PieChart";

const pieColors = {
  orange: "#ff751b",
  darkBlue: "#020183",
};

const CustomPieChart = ({ data, type }) => {
  const generatePieChartData = (data) => {
    if (!data) return [{ id: 0, value: data?.checkout?.balanceDue, label: "Balance Due" }];

    const generatePaymentData = (data) => {
      const groupedPayments = groupPaymentsByCardAndCash(data, type);

      const creditData = Object.entries(groupedPayments.credit).map(([key, amount], index) => ({
        id: index + 1,
        label: key,
        value: amount,
        color: pieColors.darkBlue,
      }));

      const cashData =
        groupedPayments.cash > 0
          ? [
              {
                id: creditData.length + 1,
                label: "Cash",
                value: groupedPayments.cash,
                color: pieColors.darkBlue,
              },
            ]
          : [];

      return [...creditData, ...cashData];
    };

    let pieChartData;

    pieChartData = generatePaymentData(data);

    // Conditionally include Balance Due if it's greater than 0
    if (data?.checkout?.balanceDue > 0) {
      pieChartData.unshift({
        id: 0,
        value: data?.checkout?.balanceDue,
        label: "Balance Due",
        color: pieColors.orange,
      });
    }

    return pieChartData;
  };

  return (
    <PieChart
      series={[
        {
          data: generatePieChartData(data),
          arcLabel: (params) => `$${params.value.toString()}` ?? "",
          innerRadius: 11,
          outerRadius: 100,
          paddingAngle: 0,
          cornerRadius: 5,
        },
      ]}
      slotProps={{
        legend: {
          direction: "row",
          position: { vertical: "bottom", horizontal: "middle" },
          padding: 0,
        },
      }}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: "white",
        },
      }}
      margin={{ top: 100, bottom: 100, left: 100, right: 100 }}
      width={300}
      height={300}
    />
  );
};

export default CustomPieChart;
