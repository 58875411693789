import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckLabel from "./CheckLabel";
import ItemsSection from "./ItemsSection";
import DiscountsSection from "./discounts/DiscountsSection";
import TaxesSection from "./TaxesSection";
import ServiceChargesSection from "./ServiceChargesSection";
import PaymentsSection from "./PaymentsSection";
import { justPayPageTypesEnum } from "../../../enums/justPayPageTypesEnum";
import { setCheck } from "../../../store/actions/justPayActions";
import CheckoutModals from "./CheckoutModals";
import CheckoutPaymentPreview from "./CheckoutPaymentPreview";
import CheckoutModalDefinitions from "./CheckoutModalDefinitions";
import { defaultTipPercentage } from "../../../config/keys";
import { calculatePaymentAmounts } from "../../../utils/calculatePaymentAmounts";

const CheckoutInfo = ({ entity, existingCheckSplit, heading }) => {
  const { entityType, guid } = entity;

  const {
    showCheckSplitModal,
    showPaymentModal,
    showPaymentTipModal,
    onOpenCheckSplitModalHandler,
    onCloseCheckSplitModalHandler,
    onOpenPaymentModalHandler,
    onClosePaymentModalHandler,
    onOpenPaymentTipModalHandler,
    onClosePaymentTipModalHandler,
  } = CheckoutModalDefinitions();

  const dispatch = useDispatch();

  const {
    justPay: {
      check: { split, paymentPercentage, paymentAmount, paymentTipPercentage },
    },
  } = useSelector((state) => state);

  const {
    // totalAmount,
    // settledAmount,
    // balanceDue,
    // stepAmount,

    settledPct,
    // balanceDuePct,
    // stepPct,

    actionButtons,
    currentPaymentAmount,
    currentPaymentPct,
    currentPaymentTipAmount,
    currentPaymentTipPct,
  } = calculatePaymentAmounts({
    split: split ?? existingCheckSplit ?? 1,
    totalAmount: entity?.checkout?.totalAmount,
    balanceDue: entity?.checkout?.balanceDue,
    voilaSubTotalAmount: entity?.checkout?.voilaSubTotalAmount,
    paymentTipPercentage: paymentTipPercentage ?? defaultTipPercentage,
  });

  // console.log("CheckoutInfo calculatePaymentAmounts",
  //   {
  //     totalAmount,
  //     settledAmount,
  //     balanceDue,
  //     stepAmount,

  //     settledPct,
  //     balanceDuePct,
  //     stepPct,

  //     actionButtons,
  //     currentPaymentAmount,
  //     currentPaymentPct,
  //     currentPaymentTipAmount,
  //     currentPaymentTipPct,
  //   }
  // )

  useEffect(() => {
    if (entityType === justPayPageTypesEnum.check) {
      dispatch(
        setCheck({
          checkGuid: guid,
          split: split ?? existingCheckSplit,
          paymentPercentage: paymentPercentage ?? currentPaymentPct,
          paymentAmount: paymentAmount ?? currentPaymentAmount,
          paymentTipPercentage: currentPaymentTipPct,
          paymentTipAmount: currentPaymentTipAmount,
        }),
      );
    }
  }, [
    dispatch,
    entityType,
    guid,
    split,
    existingCheckSplit,
    paymentPercentage,
    paymentAmount,
    currentPaymentPct,
    currentPaymentAmount,
    currentPaymentTipAmount,
    currentPaymentTipPct,
  ]);

  return (
    <div className="checkout-info-container">
      <p className="checkout-info-heading">{heading}</p>

      <div className="checkout-info-inner-container">
        <DiscountsSection entity={entity} />

        <ItemsSection entity={entity} />

        <TaxesSection entity={entity} />

        <ServiceChargesSection entity={entity} />

        <CheckLabel label={<strong>Total:</strong>} amount={entity?.checkout?.totalAmount} />

        <PaymentsSection entity={entity} />

        <hr />

        {entity?.checkout?.balanceDue > 0 ? (
          <>
            {entityType === justPayPageTypesEnum.check && (
              <>
                <CheckoutModals
                  checkout={entity.checkout}
                  showCheckSplitModal={showCheckSplitModal}
                  showPaymentModal={showPaymentModal}
                  showPaymentTipModal={showPaymentTipModal}
                  onCloseCheckSplitModalHandler={onCloseCheckSplitModalHandler}
                  onClosePaymentModalHandler={onClosePaymentModalHandler}
                  onClosePaymentTipModalHandler={onClosePaymentTipModalHandler}
                  onOpenPaymentModalHandler={onOpenPaymentModalHandler}
                  actionButtons={actionButtons}
                  settledPct={settledPct}
                />
                <CheckoutPaymentPreview
                  checkout={entity.checkout}
                  onOpenCheckSplitModalHandler={onOpenCheckSplitModalHandler}
                  onOpenPaymentModalHandler={onOpenPaymentModalHandler}
                  onOpenPaymentTipModalHandler={onOpenPaymentTipModalHandler}
                  alreadySplittedCheck={existingCheckSplit}
                  actionButtons={actionButtons}
                />
              </>
            )}
          </>
        ) : (
          <>
            <CheckLabel label="Tipped:" amount={entity?.checkout?.tipsAmount} />
            <CheckLabel label={<strong>Grand total:</strong>} amount={entity?.checkout?.grandTotalAmount} />

            <CheckLabel
              label={<strong>The {entityType.toLowerCase()} is paid, thank you for dining with us</strong>}
              labelClassName="check-label-thank-you"
            />

            {entityType === justPayPageTypesEnum.check && <>TODO additional tip</>}
          </>
        )}
      </div>
    </div>
  );
};

export default CheckoutInfo;
