import { SET_JUST_PAY_TABLE_ORDER, SET_JUST_PAY_TABLE_ORDERS, SET_JUST_PAY_CHECK } from "../../config/actionTypes";

const initialState = {
  orders: [],
  order: {},
  check: {
    checkGuid: null,
    split: null,
    paymentPercentage: null,
    paymentAmount: null,
    paymentTipPercentage: null,
    paymentTipAmount: null,
    details: {},
  },
};

const justPayReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_JUST_PAY_TABLE_ORDERS:
      return { ...state, orders: action.payload };
    case SET_JUST_PAY_TABLE_ORDER:
      return { ...state, order: action.payload };
    case SET_JUST_PAY_CHECK:
      return { ...state, check: { ...state.check, ...action.payload } };

    default:
      return state;
  }
};

export default justPayReducer;
