export const landing_path = "/";
export const home_path = "/home";
export const aboutus_page_path = "/about-us";
export const help_page_path = "/help";
export const terms_page_path = "/terms";
export const privacy_page_path = "/privacy";
export const contact_page_path = "/contact";

export const login_path = "/login";
export const register_path = "/register";
export const register_confirmation_path = "/register/:confirmationToken";
export const password_reset_path = "/password-reset/:token";
export const profile_page_path = "/profile";
export const password_change_path = "/password-change";
export const credit_card_information_path = "/credit-card-information";
export const loyalty_program_path = "/loyalty";

export const restaurant_path = "/restaurant/:restaurantId";
export const full_menu_path = `${restaurant_path}/full-menu`;
export const table_number_page_path = `${restaurant_path}/choose-table`;

export const qr_order_landing_path = "/qr-order-landing/:restaurantId/:tableNumber";
export const order_first_time_path = "/add-order/:restaurantId/:tableNumber";

export const reservations_path_now = "/dining/now";
export const reservations_path_upcoming = "/dining/upcoming";
export const reservations_path_past = "/dining/past";
export const order_page_path = "/dining/now/order/:orderId";
export const booking_order_page_path = "/dining/upcoming/order/:orderId";
export const past_order_page_path = "/dining/past/order/:orderId";

export const just_pay_path = "/dining/just-pay";
export const just_pay_table_orders_path = `${just_pay_path}/restaurant/:restaurantGuid/table/:tableGuid`;
export const just_pay_table_order_path = `${just_pay_path}/restaurant/:restaurantGuid/table/:tableGuid/order/:orderGuid`;
export const just_pay_table_order_check_path = `${just_pay_path}/restaurant/:restaurantGuid/table/:tableGuid/order/:orderGuid/check/:checkGuid`;

export const booking_page_path = `${restaurant_path}/book`;
export const booking_success_path = `${restaurant_path}/booking/:orderId`;
export const booking_reservation_path = `${restaurant_path}/booking/:orderId`;
export const accept_invitation_path = "/accept-invitation/:token";

export const anonymous_restricted_routes = {
  profile_page_path,
  booking_page_path,
  table_number_page_path,
};
